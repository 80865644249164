import type { RawAxiosResponseHeaders } from 'axios';

export const LOCAL_STORAGE_KEY = 'auth/token';
export const STORAGE_KEY = 'auth/token';

export interface AuthData {
  accessToken: string | undefined;
  tokenType: string | undefined;
  client: string | undefined;
  expiry: string | undefined;
  uid: string | undefined;
}

const readHeaderValue = (headers: RawAxiosResponseHeaders, key: string) => {
  const value =
    headers[key] ??
    // dash case
    headers[key.replaceAll(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)] ??
    undefined;
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return value == null ? value : String(value);
};
export const AuthData = {
  fromHTTPHeaders(headers: RawAxiosResponseHeaders): AuthData {
    return {
      accessToken: readHeaderValue(headers, 'accessToken'),
      tokenType: readHeaderValue(headers, 'tokenType'),
      client: readHeaderValue(headers, 'client'),
      expiry: readHeaderValue(headers, 'expiry'),
      uid: readHeaderValue(headers, 'uid'),
    };
  },
};
