import { CrashReporter as createCrashReporter } from '@captive/crash-reporter';
import { withPlugin } from '@captive/crash-reporter/dist/vue.js';
import { SentryPlugin } from '@captive/crash-reporter/dist/plugin/sentry.js';
import * as Sentry from '@sentry/capacitor';

export const CrashReporter = withPlugin(
  createCrashReporter({
    app: 'compagnie-des-familles-application',
    version: import.meta.env.VITE_APP_VERSION,
    enabled: ['true', '1'].includes(import.meta.env.VITE_CRASH_REPORT ?? 'false'),
    debug: ['true', '1'].includes(import.meta.env.VITE_CRASH_REPORT_DEBUG ?? 'false'),
    environment: import.meta.env.VITE_SENTRY_ENV ?? import.meta.env.VITE_APP_ENV ?? 'production',
    tags: {
      // application: '',
    },
    plugin: SentryPlugin(Sentry, {
      dsn:
        import.meta.env.VITE_SENTRY_DSN ??
        'https://60d8aedad44e401ee7ed1f5767473cbe@o4508335580708864.ingest.de.sentry.io/4508336723001424',
      dist: '1',
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production.
      tracesSampleRate: Number(import.meta.env['VITE_SENTRY_TRACES_RATE'] ?? 0.1),
      // integrations: [
      //   new BrowserTracing({
      //     tracingOrigins: ['localhost' /* TODO: , 'https://yourserver.io/api' */],
      //   }),
      // ],
    }),
    // eslint-disable-next-line prettier/prettier
  }),
);
