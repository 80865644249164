/* eslint-disable promise/always-return */
/* eslint-disable unicorn/prefer-top-level-await */
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import App from './App.vue';
import { router } from './router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/fonts.css';
import './theme/variables.css';
import './app.css';

/* Components css */
import { useTokenStore } from '@/store/token';
import { Pinia } from './store';
import { CrashReporter } from './crashReporter';
import { useAuthStore } from './store/auth';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App)
  // plugins
  .use(IonicVue)
  .use(Pinia)
  .use(CrashReporter)
  .use(router);

const auth = useAuthStore();
void auth.migreUtilisateur();

const token = useTokenStore();
void token.initialize();

void router.isReady().then(() => {
  app.mount('#app');
});
