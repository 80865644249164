/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { defineStore } from 'pinia';
import axios from 'axios';
import { APIClient, APICredentials } from './data';
import { useTokenStore } from '../token';

export const useAPIClientStore = defineStore('apiClient', () => {
  // Getters

  // Actions
  function createAPIClient(_useTokenStore: typeof useTokenStore) {
    const tokenStore = _useTokenStore();
    const url = import.meta.env.VITE_APP_API_URL ?? 'http://localhost:3000';
    const name = import.meta.env.VITE_APP_ENV ?? 'production';

    return APIClient({
      env: { name, baseURL: `${url}/api` },
      credentials: () => APICredentials.fromAuthToken(tokenStore.token),
      onRefreshToken: (accessToken) => {
        void tokenStore.refresh(accessToken);
      },
      onExpireToken: (_accessToken) => {
        void tokenStore.clear();
      },
    });
  }

  function createHTTPClient(apiClient: APIClient) {
    const client = axios.create();
    client.defaults.baseURL = `${apiClient.baseURL}/v1`;
    client.defaults.headers.common['accept'] = 'application/json';
    client.defaults.headers.common['authorization'] = null;

    client.interceptors.request.use((config) => {
      const credentials = apiClient.getCredentials();
      if (credentials != null) {
        Object.assign(config.headers, credentials);
      }

      return config;
    });

    client.interceptors.response.use(
      (response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const accessToken = response.headers['access-token'];
        if (accessToken != null && accessToken !== '') {
          apiClient.onRefreshToken(accessToken);
        }
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          apiClient.onExpireToken(error.config.headers['access-token']);
        }
        throw error;
      },
    );

    return client;
  }

  const apiClient = createAPIClient(useTokenStore);
  const httpClient = createHTTPClient(apiClient);
  const request = httpClient.request.bind(httpClient);

  return { request };
});
